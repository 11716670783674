import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"email-message-card",attrs:{"outlined":""}},[(_vm.message.from)?_c('div',{staticClass:"pa-5 d-flex flex-wrap align-center"},[_c(VAvatar,{staticClass:"me-3",attrs:{"size":"38"}},[_c(VImg,{attrs:{"src":_vm.message.from.avatar}})],1),_c('div',{staticClass:"d-flex justify-center flex-column"},[_c('span',{staticClass:"text--primary font-weight-medium text-base"},[_vm._v(_vm._s(_vm.message.from.name))]),_c('span',[_vm._v(_vm._s(_vm.message.from.email))])]),_c(VSpacer),_c('div',{staticClass:"my-1"},[_c('span',{staticClass:"text--disabled text-xs me-2 text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(_vm.message.time, { dateStyle: 'long', timeStyle: 'short' })))]),_c(VBtn,{staticClass:"me-1",attrs:{"icon":"","small":""}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAttachment)+" ")])],1),_c(VBtn,{staticClass:"me-1",attrs:{"icon":"","small":""}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiStarOutline)+" ")])],1),_c(VMenu,{attrs:{"offset-y":"","min-width":"140"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,false,941777005)},[_c(VList,_vm._l((_vm.singleMessageActions),function(action){return _c(VListItem,{key:action.title,attrs:{"link":""}},[_c(VListItemIcon,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(action.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(action.title))])],1)],1)}),1)],1)],1)],1):_vm._e(),_c(VDivider),_c('div',{staticClass:"message-content ma-5",domProps:{"innerHTML":_vm._s(_vm.message.message)}}),(_vm.message.attachments && _vm.message.attachments.length)?[_c(VDivider),_c('div',{staticClass:"mx-5 my-3"},[_c('p',{staticClass:"mb-2"},[_vm._v(" Attachments ")]),_vm._l((_vm.message.attachments),function(attachment,index){return _c(VChip,{key:index,staticClass:"me-2",attrs:{"href":attachment.url,"target":"_blank","tag":"a","outlined":"","download":""}},[_c(VImg,{staticClass:"me-2",attrs:{"src":attachment.thumbnail,"width":"16px"}}),_c('span',{staticClass:"text-xs align-text-top"},[_vm._v(_vm._s(attachment.fileName))])],1)})],2)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }