import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"email-compose-card"},[_c('div',{staticClass:"compose-header px-3 py-2 d-flex align-center"},[_c('span',{staticClass:"text--primary font-weight-medium text-base"},[_vm._v("Compose Mail")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('close-dialog');}}},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMinus)+" ")])],1),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('update:isDialogFullscreen', !_vm.isDialogFullscreen)}}},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.isDialogFullscreen? _vm.icons.mdiFullscreenExit : _vm.icons.mdiFullscreen)+" ")])],1),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":_vm.discardEmail}},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c(VForm,[_c(VCombobox,{key:_vm.shallShowCCFIeld.toString() + _vm.shallShowBCCFIeld.toString(),staticClass:"pt-0",attrs:{"items":_vm.emailToOptions,"filter":_vm.filterEmailToOptions,"multiple":"","chips":"","small-chips":"","deletable-chips":"","hide-selected":"","hide-details":"","item-text":"name","item-value":"email","solo":"","flat":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.item;
return [_c(VAvatar,{staticClass:"me-2",attrs:{"size":"22"}},[_c(VImg,{attrs:{"src":option.avatar}})],1),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(option.name))])]}},{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"text--primary"},[_vm._v("To:")])]},proxy:true},{key:"append",fn:function(){return [_c('span',[_vm._v(" ")])]},proxy:true},{key:"append-outer",fn:function(){return [_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.shallShowCCFIeld = !_vm.shallShowCCFIeld}}},[_vm._v(" Cc ")]),_c(VBtn,{staticClass:"me-6 ms-1",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.shallShowBCCFIeld = !_vm.shallShowBCCFIeld}}},[_vm._v(" Bcc ")])]},proxy:true}]),model:{value:(_vm.composeData.to),callback:function ($$v) {_vm.$set(_vm.composeData, "to", $$v)},expression:"composeData.to"}}),_c(VDivider),_c(VCombobox,{directives:[{name:"show",rawName:"v-show",value:(_vm.shallShowCCFIeld),expression:"shallShowCCFIeld"}],staticClass:"pt-0",attrs:{"items":_vm.emailToOptions,"filter":_vm.filterEmailToOptions,"multiple":"","solo":"","flat":"","chips":"","small-chips":"","deletable-chips":"","hide-selected":"","auto-select-first":"","hide-details":"","item-text":"name","item-value":"email"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.item;
return [_c(VAvatar,{staticClass:"me-2",attrs:{"size":"22"}},[_c(VImg,{attrs:{"src":option.avatar}})],1),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(option.name))])]}},{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"text--primary"},[_vm._v("Cc:")])]},proxy:true},{key:"append",fn:function(){return [_c('span',[_vm._v(" ")])]},proxy:true}]),model:{value:(_vm.composeData.cc),callback:function ($$v) {_vm.$set(_vm.composeData, "cc", $$v)},expression:"composeData.cc"}}),_c(VDivider,{directives:[{name:"show",rawName:"v-show",value:(_vm.shallShowCCFIeld),expression:"shallShowCCFIeld"}]}),_c(VCombobox,{directives:[{name:"show",rawName:"v-show",value:(_vm.shallShowBCCFIeld),expression:"shallShowBCCFIeld"}],staticClass:"pt-0",attrs:{"items":_vm.emailToOptions,"filter":_vm.filterEmailToOptions,"multiple":"","solo":"","flat":"","chips":"","small-chips":"","deletable-chips":"","hide-selected":"","auto-select-first":"","hide-details":"","item-text":"name","item-value":"email"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.item;
return [_c(VAvatar,{staticClass:"me-2",attrs:{"size":"22"}},[_c(VImg,{attrs:{"src":option.avatar}})],1),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(option.name))])]}},{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"text--primary"},[_vm._v("Bcc:")])]},proxy:true},{key:"append",fn:function(){return [_c('span',[_vm._v(" ")])]},proxy:true}]),model:{value:(_vm.composeData.bcc),callback:function ($$v) {_vm.$set(_vm.composeData, "bcc", $$v)},expression:"composeData.bcc"}}),_c(VDivider,{directives:[{name:"show",rawName:"v-show",value:(_vm.shallShowBCCFIeld),expression:"shallShowBCCFIeld"}]}),_c(VTextField,{staticClass:"mb-1 pt-0",attrs:{"hide-details":"","solo":"","flat":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"text--primary"},[_vm._v("Subject:")])]},proxy:true}]),model:{value:(_vm.composeData.subject),callback:function ($$v) {_vm.$set(_vm.composeData, "subject", $$v)},expression:"composeData.subject"}}),_c(VDivider),_c(VTextarea,{staticClass:"email-compose-message",attrs:{"solo":"","flat":"","label":"Message","hide-details":"","auto-grow":""},model:{value:(_vm.composeData.message),callback:function ($$v) {_vm.$set(_vm.composeData, "message", $$v)},expression:"composeData.message"}}),_c(VDivider),_c('div',{staticClass:"pa-4 d-flex align-center"},[_c(VMenu,{attrs:{"offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtnToggle,{staticClass:"send-btn-toggle me-3",attrs:{"color":"primary","borderless":""}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.sendMail}},[_c('span',[_vm._v("Send")])]),_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronDown)+" ")])],1)],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,[_vm._v("Schedule Send")])],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,[_vm._v("Save as Draft")])],1)],1)],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiAttachment)+" ")])],1),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":"","top":"","min-width":"140"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,[_vm._v("Print")])],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,[_vm._v("Check Spelling")])],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,[_vm._v("Plain Text Mode")])],1)],1)],1),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.discardEmail}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }